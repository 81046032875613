import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Spin } from 'antd';
import { YourVeterinariansStyles } from './styles';
import { EyeOutlined } from '@ant-design/icons';
import defaultAvatar from '../../images/avatar-default-blue.png';
import { getAllVetApi } from '../../api/getAllVet';

const YourVeterinarians: FC<YourVeterinariansProps> = () => {
  const [vet, setVetBanner] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getAllVetApi().then((res) => {
      setVetBanner(res?.vets || []);
      setLoading(false);
    });
  }, []);

  return (
    <YourVeterinariansStyles id="yourVeterinarians">
      <div className="feature-content">
        <div className="title">Your veterinarians</div>
        <div className="sub-title">
          Select your preferred vet, or for urgent issues choose the next
          available vet.
        </div>
      </div>
      <div className="vet-section">
        {loading ? (
          <Spin />
        ) : (
          <Row gutter={[0, 20]}>
            {vet?.map((item) => (
              <Col lg={6} md={12} xs={24} className="vet-content">
                {item.avatar ? (
                  <img src={item.avatar} className="vet-img" />
                ) : (
                  <div className="vet-img">
                    <img src={defaultAvatar} className="vet-icon" />
                  </div>
                )}
                <div className="vet-name">{`${item.firstName} ${item.lastName}`}</div>
                <Button
                  className="btn-view"
                  href={`/veterinarians/${item.profileSlug}`}
                  onClick={() => {
                    localStorage.setItem('profileSlug', item.profileSlug);
                  }}
                >
                  <EyeOutlined />
                </Button>
              </Col>
            ))}
          </Row>
        )}
      </div>
    </YourVeterinariansStyles>
  );
};

export default YourVeterinarians;
