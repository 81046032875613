import React from 'react';
import YourVeterinarians from '../../components/YourVeterinarians';
import LayoutWrapper from '../../components/LayoutWrapper';
import { CMSWrapper } from '../../configs/globalStyle';

const YourVeterinarianPage = (): FC => {
  return (
    <LayoutWrapper>
      <CMSWrapper>
        <YourVeterinarians />
      </CMSWrapper>
    </LayoutWrapper>
  );
};

export default YourVeterinarianPage;
