import styled from 'styled-components';
import theme from 'configs/theme';

export const YourVeterinariansStyles = styled.div`
  margin: 90px auto;

  .feature-content {
    width: 100%;
    text-align: center;
    padding: 20px 0px 40px;

    .title {
      font-family: DM Sans;
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 48px;
      letter-spacing: 0px;
    }

    .sub-title {
      font-family: DM Sans;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 36px;
    }
  }

  .vet-section {

    .ant-spin {
      display: flex;
      justify-content: center;
    }
    .ant-row {
      display: flex;
      justify-content: center;
    }

    .vet-content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 15px;
      position: relative;

      img {
        width: 200px;
        height: 200px;
        object-fit: cover;
        border-radius: 12px;
        margin-bottom: 15px;
      }

      .vet-img {
        width: 200px;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
        margin-bottom: 14px;
        background: #d4eef9;
        .vet-icon {
          width: 100px;
          height: auto;
        }
      }

      .vet-name {
        font-family: DM Sans;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
        text-align: center;
      }

      .btn-view {
        position: absolute;
        top: 35%;
        border: none;
        padding: 0;
        height: fit-content;
        background: transparent;
        box-shadow: none;
        color: #fff;
        display: none;
        text-align: center;

        .anticon {
          font-size: 20px;
          margin: 0px;
        }
      }
    }

    .vet-content:hover {
      img {
        filter: brightness(50%);
      }

      .btn-view {
        display: inline-block;
      }
      .vet-img {
        background: #1b191969;
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    .vet-section {
      padding: 0 100px;
    }
  }

  @media only screen and (max-width: 576px) {
    margin: 90px 20px;

    .vet-section {
      padding: 0 30px;
    }
  }
`;
